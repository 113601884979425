export const i18nStores = {
  success: {
    en: "Success",
    zhHant: "成功",
    zhHans: "成功",
    ko: "성공",
    vi: "Sự thành công",
    tr: "Başarı",
    id: "Keberhasilan",
    ja: "成功",
    ru: "Успех",
    pt: "Sucesso",
    es: "Éxito",
  },
  error: {
    en: "Error",
    zhHant: "錯誤",
    zhHans: "错误",
    ko: "오류",
    vi: "lỗi",
    tr: "Hata",
    id: "Kesalahan",
    ja: "エラー",
    ru: "Ошибка",
    pt: "Erro",
    es: "Error",
  },
  loading: {
    en: "Loading",
    zhHant: "載入中",
    zhHans: "载入中",
    ko: "로딩중",
    vi: "Đang tải",
    tr: "Yükleniyor",
    id: "Memuat",
    ja: "ロード中",
    ru: "Загрузка",
    pt: "Carregando",
    es: "Cargando",
  },
  waitConfirmations: {
    en: "Waiting for Block Confirmations...",
    zhHant: "等待區塊確認中...",
    zhHans: "等待区块确认中...",
    ko: "블록확인 대기중...",
    vi: "Đang chờ Block xác nhận ...",
    tr: "Blok onayları bekleniyor...",
    id: "Menunggu Konfirmasi Blok ...",
    ja: "ブロック確認待機中...",
    ru: "Ожидание подтверждений блока...",
    pt: "Aguardando confirmações de bloqueio ...",
    es: "Esperando por las confirmaciones del bloque...",
  },
  verifyingTransaction: {
    en: "Validators Verifying Transaction...",
    zhHant: "等待驗證交易中...",
    zhHans: "等待验证交易中...",
    ko: "거래검증 대기중...",
    vi: "Validators đang xác minh giao dịch ...",
    tr: "Doğrulayıcılar işlemi doğruluyor..",
    id: "Validator Sedang Memverifikasi Transaksi ...",
    ja: "取引検証待機中...",
    ru: "Валидаторы верифицируют транзакцию...",
    pt: "Validadores verificando transação ...",
    es: "Validadores: verificando la transacción...",
  },
  transferComplete: {
    en: "Transfer Complete",
    zhHant: "轉帳完成",
    zhHans: "转帐完成",
    ko: "이체완료",
    vi: "Gửi hoàn tất",
    tr: "Transfer tamamlandı",
    id: "Transfer Selesai",
    ja: "振替完了",
    ru: "Перевод завершен",
    pt: "Transferência Completa",
    es: "Transferencia completada",
  },
  errWrongNetwork: {
    en: "Wrong network error",
    zhHant: "網路錯誤",
    zhHans: "网路错误",
    ko: "네트워크 오류",
    vi: "Lỗi mạng",
    tr: "Yanlış ağ hatası",
    id: "Kesalahan jaringan",
    ja: "ネットワークエラー",
    ru: "Ошибка неправильной сети",
    pt: "Erro de rede errado",
    es: "Error de Red Incorrecta",
  },
  errHomeConnection: {
    en: "Home Connection Error",
    zhHant: "對內連結錯誤",
    zhHans: "对内连结错误",
    ko: "내부연결오류",
    vi: "Lỗi kết nối nội bộ",
    tr: "Ana Ağ Bağlantı Hatası",
    id: "Kesalahan Koneksi Rumah",
    ja: "内部接続エラー",
    ru: "Ошибка подключения к домашней сети",
    pt: "Erro de conexão doméstica",
    es: "Error de conexión Interna",
  },
  errForeignConnection: {
    en: "Foreign Connection Error",
    zhHant: "對外連結錯誤",
    zhHans: "对外连结错误",
    ko: "외부연결오류",
    vi: "Lỗi kết nối bên ngoài",
    tr: "Yabancı Ağ Bağlantı Hatası",
    id: "Kesalahan Sambungan Asing",
    ja: "外部接続エラー",
    ru: "Ошибка подключения к другой сети",
    pt: "Erro de conexão externa",
    es: "Error de conexión externa",
  },
  successHomeTransfer: {
    en: "Home Transfer Success",
    zhHant: "對內轉帳成功",
    zhHans: "对内转帐成功",
    ko: "내부이체성공",
    vi: "Gửi nội bộ thành công",
    tr: "Ana Ağa Transfer Başarılı",
    id: "Transfer Rumah Berhasil",
    ja: "内部への振替成功",
    ru: "Перевод в домашнюю сеть успешен",
    pt: "Sucesso na transferência de casa",
    es: "Transferencia interna exitosa",
  },
  successForeignTransfer: {
    en: "Foreign Transfer Success",
    zhHant: "對外轉帳成功",
    zhHans: "对外转帐成功",
    ko: "외부이체성공",
    vi: "Gửi bên ngoài thành công",
    tr: "Ağ Transferi Başarılı",
    id: "Sukses Transfer Luar Negeri",
    ja: "外部への振替成功",
    ru: "Перевод в другую сеть успешен",
    pt: "Sucesso de transferência estrangeira",
    es: "Transferencia externa exitosa",
  },
  unlockWallet: {
    en: "Please unlock wallet",
    zhHant: "請解鎖錢包",
    zhHans: "请解锁钱包",
    ko: "지갑을 해제하세요",
    vi: "Vui lòng mở khóa ví",
    tr: "Lütfen cüzdan kilidinizi açın",
    id: "Silakan buka kunci dompet",
    ja: "財布を解除してください",
    ru: "Пожалуйста разблокируйте кошелек",
    pt: "Por favor, desbloqueie a carteira",
    es: "Por favor, desbloquee su billetera",
  },
  transactionRejected: {
    en: "Transaction rejected on wallet",
    zhHant: "錢包上的交易被拒絕",
    zhHans: "钱包上的交易被拒绝",
    ko: "지갑의 거래가 거부되었습니다",
    vi: "Giao dịch bị từ chối trên ví",
    tr: "İşlem cüzdanda reddedildi",
    id: "Transaksi ditolak di dompet",
    ja: "ウォレットでトランザクションが拒否されました",
    ru: "Транзакция отменена",
    pt: "Transação rejeitada na carteira",
    es: "Transacción rechazada en la billetera",
  },
  unknownNetwork: {
    en:
      "You are on an unknown network on your wallet. Please select {homeNet} or {foreignNet} in order to communicate with the bridge.",
    zhHant:
      "您的錢包目前連接未知網路。請選擇 {homeNet} 或 {foreignNet} 以便與橋連接。",
    zhHans:
      "您的钱包目前连接未知网路。请选择 {homeNet} 或 {foreignNet} 以便与桥连接。",
    ko:
      "현재 지갑이 알 수 없는 네트워크에 연결되었습니다. {homeNet} 또는 {foreignNet} 을 선택하여 브리지에 연결하세요.",
    vi:
      "Bạn đang sử dụng một mạng không xác định trên ví của mình. Vui lòng chọn {homeNet} hoặc {foreignNet} để giao tiếp với bridge.",
    tr:
      "Cüzdanınızda bilinmeyen bir ağ üzerindesiniz. Bridge ile bağlanmak için lütfen {homeNet} veya {foreignNet} seçin.",
    id:
      "Anda berada di jaringan yang tidak dikenal di dompet Anda. Harap pilih {homeNet} atau {foreignNet} untuk berkomunikasi dengan bridge.",
    ja:
      "現在の財布が知ることができないネットワークに接続された。 {homeNet}または{foreignNet}を選択して、ブリッジに接続します。",
    ru:
      "Ваш кошелек в неизвестной сети. Пожалуйста выберите {homeNet} или {foreignNet} для взаимодействия с мостом.",
    pt:
      "Você está em uma rede desconhecida em sua carteira. Selecione {homeNet} ou {foreignNet} para se comunicar com a ponte.",
    es:
      "Su billetera está en una red desconocida. Por favor, seleccione la red {homeNet} ó {foreignNet} para poder comunicarse con el puente.",
  },
}
