import React from "react";
import { bridgeType } from "../stores/utils/bridgeMode";
import { FormattedMessage } from "react-intl";
import { inject } from "mobx-react";

const textSelector = (chain) => {
  switch (chain) {
    case "eth":
      return (
        <FormattedMessage id="components.i18n.SwithChainButton.ethereumNetwork" />
      );
    case "bsc":
      return (
        <FormattedMessage id="components.i18n.SwithChainButton.bscNetwork" />
      );
    case "heco":
      return (
        <FormattedMessage id="components.i18n.SwithChainButton.hecoNetwork" />
      );
  }
};

@inject("RootStore")
class SwithChainButton extends React.Component {
  cleanInterval = (intervalId1, intervalId2) => {
    if (intervalId1 !== 0 && intervalId2 !== 0) {
      console.log("Clear ", intervalId1, intervalId2);
      clearInterval(intervalId1);
      clearInterval(intervalId2);
    }
  };

  render() {
    const renderChains = ["eth", "bsc"].filter((n) => {
      return n !== bridgeType;
    });
    const { homeStore, foreignStore } = this.props.RootStore;

    return (
      <div className="switch-chain-container">
        {renderChains.map((chain) => (
          <div className="switch-chain-wrapper">
            <a
              href={`${window.origin}/${chain}/`}
              onClick={this.cleanInterval(
                homeStore.intervalID,
                foreignStore.intervalID
              )}
            >
              <button className="switch-chain-button" type="button">
                <span className="to">
                  <FormattedMessage id="components.i18n.SwithChainButton.switchTo" />
                </span>
                <span className="text">{textSelector(chain)}</span>
              </button>
            </a>
          </div>
        ))}
      </div>
    );
  }
}

export default SwithChainButton;
